import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import router from './router'
import store from './vuex/store'

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;


// API mixins
Vue.mixin({
  methods: {
    async $apiRequest({config={}, method, url, data, handleError=true} = {}) {

      let axiosConfig = config;
      if (method) axiosConfig.method = method;
      if (url) {
        axiosConfig.url = `${process.env.VUE_APP_API_BASE_URL}${url}`;
      }
      if (data) {
        if (axiosConfig.method === 'get') {
          axiosConfig.params = data;
        }
        else {
          axiosConfig.data = data;
        }
      }
    
      return axios(axiosConfig).catch((error) => {
        if (handleError) {
          if (this.$apiHandleError(error)) return;
        }
        throw error;
      });
    },

    $apiHandleError(error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorCode = error.response.data.error;
        const errorMessage = error.response.data.errorString || (this.$t('message.errorWithDescription') + errorCode);

        if (errorCode === 'loginRequired') {
          this.$router.replace({ name: 'UserLogin' });
          return true;
        }
  
        this.$root.messageSnackBar.showError(errorMessage);
        return true;
      }

      return false;
    },

    $fileUrl(url) {
      return `${process.env.VUE_APP_FILE_BASE_URL}/${url}`;
    },
  },
});


// string mixins
Vue.mixin({
  methods: {
    $adCommandWorkerName(item) {
      if (item.executantType === 'carrier') return this.$t('listKey.carrier');
      else if (item.executantType === 'operator') return this.$t('listKey.operator');
      return item.worker.name;
    },

    $adUnitPositionName(position) {
      console.log(position);
      console.log('haha');
      if ((''+position).startsWith('bulk')) return `${this.$t('listValue.bulkUpload')} ${(''+position).slice(4)}`;
      return this.$t(`adUnitPosition.${position}`) || position;
    },
  },
});


// util mixins
Vue.mixin({
  methods: {
    $currentDate() {
      const d = new Date();
      const year = `${d.getFullYear()}`;
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return `${year}-${month}-${day}`;
    },
  },
});



new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

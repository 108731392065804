<template>
  <v-card :loading="loadingEditFields || loadingItem">
    <v-form ref="form" :disabled="loadingEditFields || loadingItem || processing" @submit.prevent="processForm" lazy-validation>
      
      <div v-for="(editField, editFieldIndex) in editFields" :key="editField.key">

        <template v-if="editField.type === 'text'">
          <v-text-field type="text" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" @change="handleValueChange(editFieldIndex)" clearable></v-text-field>
        </template>

        <template v-if="editField.type === 'textarea'">
          <v-textarea type="text" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" @change="handleValueChange(editFieldIndex)"></v-textarea>
        </template>

        <template v-if="editField.type === 'json'">
          <v-textarea type="text" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" @change="handleValueChange(editFieldIndex)"></v-textarea>
        </template>

        <template v-if="editField.type === 'currency'">
          <v-text-field type="number" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" prefix="₩" @change="handleValueChange(editFieldIndex)" clearable></v-text-field>
        </template>

        <template v-if="editField.type === 'number'">
          <v-text-field type="number" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" @change="handleValueChange(editFieldIndex)" clearable></v-text-field>
        </template>

        <template v-if="editField.type === 'select'">
          <v-autocomplete v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :items="editField.items" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (v !== undefined) || (editField.allowNull || v != null)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" @change="handleValueChange(editFieldIndex)">
          </v-autocomplete>
        </template>

        <template v-if="editField.type === 'date'">
          <v-text-field type="date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" max="2999-12-31" v-model="values[editField.key]" :tabindex="editFieldIndex+1" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" :placeholder="editField.placeholder" @change="handleValueChange(editFieldIndex)"></v-text-field>
        </template>

        <template v-if="editField.type === 'radio'">
          <v-radio-group v-model="values[editField.key]" :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (!!v)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" @change="handleValueChange(editFieldIndex)" row>
            <v-radio v-for="radioItem in editField.items" :key="radioItem.value" :label="radioItem.text" :value="radioItem.value"></v-radio>
          </v-radio-group>
        </template>

        <template v-if="editField.type === 'checkbox'">
          <v-radio-group :ref="`formField_${editField.key}`" :label="$t(`listKey.${editField.listKey || editField.key}`) + (editField.required ? '*' : '')" :rules="[v => (!editField.required || (values[editField.key].length > 0)) || $t('error.requiredField')]" :required="editField.required" :disabled="!(!id || (id && editField.editable))" row>
            <v-checkbox v-for="checkItem in editField.items" :key="`formField_${editField.key}_checkbox_${checkItem.value}`" :label="checkItem.text" :value="checkItem.value" v-model="values[editField.key]" @change="handleValueChange(editFieldIndex)" class="shrink mr-3 mt-0 pt-0" hide-details></v-checkbox>
          </v-radio-group>
        </template>

      </div>

      <div class="mt-4 d-flex justify-end">
        <v-btn class="ml-2" @click="cancel">{{ $t('operation.cancel') }}</v-btn>
        <v-btn class="ml-2" type="submit" color="primary" @click="processForm">{{ $t('operation.finish') }}</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'CommonEditPage',

  components: {
  },

  props: {
    id: {
      default: '',
    },
    url: {
      default: '',
    },
    initialValues: {
      default: () => ({}),
    },
  },

  data: () => ({
    loadingEditFields: false,
    loadingItem: false,
    editFields: [],
    editFieldsByKey: {},
    values: {},
    processing: false,
    editFinished: false,
  }),

  created() {
    this.values = {...this.initialValues};
  },

  mounted() {
    if (!this.id) {
      if (this.url === '/ad/command') {
        this.$nextTick(() => {
          this.$router.push({ name: 'AdCommandCreationWizard', query: this.initialValues });
        });
        return;
      }
      else if (this.url === '/ad/command_report') {
        this.$nextTick(() => {
          this.$router.push({ name: 'AdCommandReportCreationWizard', query: this.initialValues });
        });
        return;
      }
    }

    this.fetchInitialData();
  },

  methods: {
    async fetchInitialData() {
      await this.fetchItem();
      await this.fetchEditFields();
    },

    async fetchItem() {
      if (this.loadingItem) return;
      if (!this.id) return;

      this.loadingItem = true;
      let res = await this.$apiRequest({
        method: 'get',
        url: `${this.url}/${this.id}/edit`,
      });

      if (!res.data) return;
      this.values = res.data;
      this.loadingItem = false;
    },

    async fetchEditFields() {
      if (this.loadingEditFields) return;

      this.loadingEditFields = true;
      let res = await this.$apiRequest({
        method: 'post',
        url: `${this.url}/edit_fields`,
        data: this.values,
      });

      if (!res.data) return;
      let editFields = res.data || [];
      let editFieldsByKey = {};

      editFields.forEach((field) => {
        editFieldsByKey[field.key] = field;
        if (field.defaultValue !== undefined && (this.values[field.key] === undefined || this.values[field.key] === null || this.values[field.key] === '')) {
          this.values[field.key] = field.defaultValue;
        }
        if (field.type === 'checkbox') {
          this.values[field.key] = this.values[field.key] || [];
          this.values[field.key] = [...this.values[field.key]];
        }
        if (field.type === 'json') {
          if (typeof this.values[field.key] !== 'string' && !(this.values[field.key] instanceof String)) {
            this.values[field.key] = JSON.stringify(this.values[field.key], null, 2);
          }
        }
      });

      this.editFields = editFields;
      this.editFieldsByKey = editFieldsByKey;
      this.values = {...this.values};

      this.loadingEditFields = false;
    },

    handleValueChange(editFieldIndex) {
      if (this.editFields[editFieldIndex].refreshOnChange) {
        this.fetchEditFields();
      }
    },

    processForm() {
      if (this.processing) return;

      let isValid = this.$refs.form.validate();
      if (!isValid) return;

      this.processing = true;

      this.$apiRequest({
        method: 'post',
        url: this.id ? `${this.url}/${this.id}` : `${this.url}/new`,
        data: this.values,
      }).then((res) => {
        this.processing = false;

        if (res.data && res.data.id) {
          this.editFinished = true;
          this.$root.messageSnackBar.showSuccess(this.id ? this.$t('message.successEdit') : this.$t('message.successCreate'));
          this.$emit('finish', { id: res.data.id });
        }
      });
    },

    cancel() {
      this.$emit('cancel');
    },
  }
  
};
</script>

<style scoped>
.item-detail-table th {
  width: 0.1%;
  white-space: nowrap;
}
</style>
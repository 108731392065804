<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-card :loading="loading">
            <v-card-text>
              <v-row align="center" class="pa-2">
                <v-progress-circular color="primary" size="80" width="10" :indeterminate="loading" :value="deployedTaxiPercentage">
                  {{ deployedTaxiPercentage }}%
                </v-progress-circular>
                <v-layout column align-content-start class="pl-2">
                  <div class="text-body-1">
                    <b>{{ $t('listKey.numOperatingTaxis') }}</b>: {{ numTaxis || '' }}
                  </div>
                  <div class="text-body-1">
                    <b>{{ $t('listKey.numDeployedTaxis') }}</b>: {{ numDeployedTaxis || '' }}
                  </div>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card v-if="['admin'].includes(userGroupType)">
            <v-list subheader>
              <v-subheader>{{ $t('menu.adCommand') }}</v-subheader>

              <v-list-item link to="/ad/command/create_wizard">
                <v-icon left>mdi-auto-fix</v-icon>
                <v-list-item-title>{{ $t('menu.adCommandCreationWizard') }}</v-list-item-title>
              </v-list-item>

              <v-list-item link to="/ad/command/review_wizard">
                <v-icon left>mdi-playlist-check</v-icon>
                <v-list-item-title>{{ $t('menu.adCommandReviewWizard') }}</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-subheader>{{ $t('menu.adCommandReport') }}</v-subheader>

              <v-list-item link to="/ad/command_report/create_wizard">
                <v-icon left>mdi-auto-fix</v-icon>
                <v-list-item-title>{{ $t('menu.adCommandReportCreationWizard') }}</v-list-item-title>
              </v-list-item>

              <v-list-item link to="/ad/command_report/bulk_upload">
                <v-icon left>mdi-upload</v-icon>
                <v-list-item-title>{{ $t('menu.adCommandReportBulkUpload') }}</v-list-item-title>
              </v-list-item>
              
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card v-if="['admin', 'association'].includes(userGroupType)">
            <CommonList
              :isSublist="true"
              :isWizardList="false"
              :title="$t('menu.adCommandGroup')"
              url="/ad/command_group"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CommonList from '@/components/CommonList.vue';

export default {
  name: 'HomePage',

  components: {
    CommonList,
  },

  data: () => ({
    loading: false,
    numTaxis: 0,
    numDeployedTaxis: 0,
  }),

  computed: {
    userGroupType() {
      return this.$store.getters.getUserGroupType;
    },
    deployedTaxiPercentage() {
      if (this.numTaxis <= 0) return 0;
      return Math.round((this.numDeployedTaxis*100)/this.numTaxis);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.loading) return;
      this.loading = true;

      let res = await this.$apiRequest({
        method: 'get',
        url: '/taxi/taxi/stat',
      });

      this.loading = false;
      if (!res.data) return;

      this.numTaxis = res.data.numTaxis;
      this.numDeployedTaxis = res.data.numDeployedTaxis;
    },
  },

}
</script>

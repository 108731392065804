import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
  },

  {
    path: '/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "UserLogin" */ '@/views/user/UserLogin.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.login') },
  }, {
    path: '/user/logged_info',
    name: 'UserLoggedInfo',
    component: () => import(/* webpackChunkName: "UserLoggedInfo" */ '@/views/user/UserLoggedInfo.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.myAccount') },
  }, {
    path: '/user/change_password',
    name: 'UserChangePassword',
    component: () => import(/* webpackChunkName: "UserChangePassword" */ '@/views/user/UserChangePassword.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.changePassword') },
  },

  {
    path: '/association/settlement',
    name: 'AssociationSettlement',
    component: () => import(/* webpackChunkName: "AssociationSettlement" */ '@/views/association/AssociationSettlement.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.exportAssociationSettlement') },
  },

  {
    path: '/taxi/district',
    name: 'TaxiDistrictList',
    component: () => import(/* webpackChunkName: "TaxiDistrictList" */ '@/views/taxi/TaxiDistrictList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiDistrict') },
  }, {
    path: '/taxi/district/:id',
    name: 'TaxiDistrictItem',
    component: () => import(/* webpackChunkName: "TaxiDistrictItem" */ '@/views/taxi/TaxiDistrictItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiDistrict') },
    props: true,
  },
  
  {
    path: '/taxi/union',
    name: 'TaxiUnionList',
    component: () => import(/* webpackChunkName: "TaxiUnionList" */ '@/views/taxi/TaxiUnionList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiUnion') },
  }, {
    path: '/taxi/union/new',
    name: 'TaxiUnionNew',
    component: () => import(/* webpackChunkName: "TaxiCarrierEdit" */ '@/views/taxi/TaxiCarrierEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiUnion') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/taxi/union/:id',
    name: 'TaxiUnionItem',
    component: () => import(/* webpackChunkName: "TaxiUnionItem" */ '@/views/taxi/TaxiUnionItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiUnion') },
    props: true,
  }, {
    path: '/taxi/union/:id/edit',
    name: 'TaxiUnionEdit',
    component: () => import(/* webpackChunkName: "TaxiUnionEdit" */ '@/views/taxi/TaxiUnionEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiUnion') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/taxi/carrier',
    name: 'TaxiCarrierList',
    component: () => import(/* webpackChunkName: "TaxiCarrierList" */ '@/views/taxi/TaxiCarrierList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiCarrier') },
  }, {
    path: '/taxi/carrier/new',
    name: 'TaxiCarrierNew',
    component: () => import(/* webpackChunkName: "TaxiCarrierEdit" */ '@/views/taxi/TaxiCarrierEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiCarrier') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/taxi/carrier/:id',
    name: 'TaxiCarrierItem',
    component: () => import(/* webpackChunkName: "TaxiCarrierItem" */ '@/views/taxi/TaxiCarrierItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiCarrier') },
    props: true,
  }, {
    path: '/taxi/carrier/:id/edit',
    name: 'TaxiCarrierEdit',
    component: () => import(/* webpackChunkName: "TaxiCarrierEdit" */ '@/views/taxi/TaxiCarrierEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiCarrier') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/taxi/taxi',
    name: 'TaxiTaxiList',
    component: () => import(/* webpackChunkName: "TaxiTaxiList" */ '@/views/taxi/TaxiTaxiList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxi') },
  }, {
    path: '/taxi/taxi/new',
    name: 'TaxiTaxiNew',
    component: () => import(/* webpackChunkName: "TaxiTaxiEdit" */ '@/views/taxi/TaxiTaxiEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxi') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/taxi/taxi/:id',
    name: 'TaxiTaxiItem',
    component: () => import(/* webpackChunkName: "TaxiTaxiItem" */ '@/views/taxi/TaxiTaxiItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxi') },
    props: true,
  }, {
    path: '/taxi/taxi/:id/edit',
    name: 'TaxiTaxiEdit',
    component: () => import(/* webpackChunkName: "TaxiTaxiEdit" */ '@/views/taxi/TaxiTaxiEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxi') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/taxi/type',
    name: 'TaxiTypeList',
    component: () => import(/* webpackChunkName: "TaxiTypeList" */ '@/views/taxi/TaxiTypeList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiType') },
  }, {
    path: '/taxi/type/:id',
    name: 'TaxiTypeItem',
    component: () => import(/* webpackChunkName: "TaxiTypeItem" */ '@/views/taxi/TaxiTypeItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiType') },
    props: true,
  },
  
  {
    path: '/taxi/worker',
    name: 'TaxiWorkerList',
    component: () => import(/* webpackChunkName: "TaxiWorkerList" */ '@/views/taxi/TaxiWorkerList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiWorker') },
  }, {
    path: '/taxi/worker/new',
    name: 'TaxiWorkerNew',
    component: () => import(/* webpackChunkName: "TaxiWorkerEdit" */ '@/views/taxi/TaxiWorkerEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiWorker') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/taxi/worker/:id',
    name: 'TaxiWorkerItem',
    component: () => import(/* webpackChunkName: "TaxiWorkerItem" */ '@/views/taxi/TaxiWorkerItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiWorker') },
    props: true,
  }, {
    path: '/taxi/worker/:id/edit',
    name: 'TaxiWorkerEdit',
    component: () => import(/* webpackChunkName: "TaxiWorkerEdit" */ '@/views/taxi/TaxiWorkerEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.taxiWorker') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/advertiser',
    name: 'AdAdvertiserList',
    component: () => import(/* webpackChunkName: "AdAdvertiserList" */ '@/views/ad/AdAdvertiserList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adAdvertiser') },
  }, {
    path: '/ad/advertiser/new',
    name: 'AdAdvertiserNew',
    component: () => import(/* webpackChunkName: "AdAdvertiserEdit" */ '@/views/ad/AdAdvertiserEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adAdvertiser') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/advertiser/:id',
    name: 'AdAdvertiserItem',
    component: () => import(/* webpackChunkName: "AdAdvertiserItem" */ '@/views/ad/AdAdvertiserItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adAdvertiser') },
    props: true,
  }, {
    path: '/ad/advertiser/:id/edit',
    name: 'AdAdvertiserEdit',
    component: () => import(/* webpackChunkName: "AdAdvertiserEdit" */ '@/views/ad/AdAdvertiserEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adAdvertiser') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/ad',
    name: 'AdAdList',
    component: () => import(/* webpackChunkName: "AdAdList" */ '@/views/ad/AdAdList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.ad') },
  }, {
    path: '/ad/ad/new',
    name: 'AdAdNew',
    component: () => import(/* webpackChunkName: "AdAdEdit" */ '@/views/ad/AdAdEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.ad') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/ad/:id',
    name: 'AdAdItem',
    component: () => import(/* webpackChunkName: "AdAdItem" */ '@/views/ad/AdAdItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.ad') },
    props: true,
  }, {
    path: '/ad/ad/:id/edit',
    name: 'AdAdEdit',
    component: () => import(/* webpackChunkName: "AdAdEdit" */ '@/views/ad/AdAdEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.ad') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/unit',
    name: 'AdUnitList',
    component: () => import(/* webpackChunkName: "AdUnitList" */ '@/views/ad/AdUnitList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adUnit') },
  }, {
    path: '/ad/unit/new',
    name: 'AdUnitNew',
    component: () => import(/* webpackChunkName: "AdUnitEdit" */ '@/views/ad/AdUnitEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adUnit') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/unit/:id',
    name: 'AdUnitItem',
    component: () => import(/* webpackChunkName: "AdUnitItem" */ '@/views/ad/AdUnitItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adUnit') },
    props: true,
  }, {
    path: '/ad/unit/:id/edit',
    name: 'AdUnitEdit',
    component: () => import(/* webpackChunkName: "AdUnitEdit" */ '@/views/ad/AdUnitEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adUnit') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/command_group',
    name: 'AdCommandGroupList',
    component: () => import(/* webpackChunkName: "AdCommandGroupList" */ '@/views/ad/AdCommandGroupList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandGroup') },
  }, {
    path: '/ad/command_group/new',
    name: 'AdCommandGroupNew',
    component: () => import(/* webpackChunkName: "AdCommandGroupEdit" */ '@/views/ad/AdCommandGroupEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandGroup') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/command_group/:id',
    name: 'AdCommandGroupItem',
    component: () => import(/* webpackChunkName: "AdCommandGroupItem" */ '@/views/ad/AdCommandGroupItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandGroup') },
    props: true,
  }, {
    path: '/ad/command_group/:id/edit',
    name: 'AdCommandGroupEdit',
    component: () => import(/* webpackChunkName: "AdCommandGroupEdit" */ '@/views/ad/AdCommandGroupEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandGroup') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/command',
    name: 'AdCommandList',
    component: () => import(/* webpackChunkName: "AdCommandList" */ '@/views/ad/AdCommandList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommand') },
  }, {
    path: '/ad/command/new',
    name: 'AdCommandNew',
    component: () => import(/* webpackChunkName: "AdCommandEdit" */ '@/views/ad/AdCommandEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommand') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/command/create_wizard',
    name: 'AdCommandCreationWizard',
    component: () => import(/* webpackChunkName: "AdCommandCreationWizard" */ '@/views/ad/AdCommandCreationWizard.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandCreationWizard') },
  }, {
    path: '/ad/command/review_wizard',
    name: 'AdCommandReviewWizard',
    component: () => import(/* webpackChunkName: "AdCommandReviewWizard" */ '@/views/ad/AdCommandReviewWizard.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReviewWizard') },
  }, {
    path: '/ad/command/:id',
    name: 'AdCommandItem',
    component: () => import(/* webpackChunkName: "AdCommandItem" */ '@/views/ad/AdCommandItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommand') },
    props: true,
  }, {
    path: '/ad/command/:id/edit',
    name: 'AdCommandEdit',
    component: () => import(/* webpackChunkName: "AdCommandEdit" */ '@/views/ad/AdCommandEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommand') + ' - ' + v.$t('operation.edit') },
    props: true,
  },
  
  {
    path: '/ad/command_report',
    name: 'AdCommandReportList',
    component: () => import(/* webpackChunkName: "AdCommandReportList" */ '@/views/ad/AdCommandReportList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReport') },
  }, {
    path: '/ad/command_report/new',
    name: 'AdCommandReportNew',
    component: () => import(/* webpackChunkName: "AdCommandReportEdit" */ '@/views/ad/AdCommandReportEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReport') + ' - ' + v.$t('operation.createNewItem') },
  }, {
    path: '/ad/command_report/create_wizard',
    name: 'AdCommandReportCreationWizard',
    component: () => import(/* webpackChunkName: "AdCommandReportCreationWizard" */ '@/views/ad/AdCommandReportCreationWizard.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReportCreationWizard') },
  }, {
    path: '/ad/command_report/bulk_upload',
    name: 'AdCommandReportBulkUpload',
    component: () => import(/* webpackChunkName: "AdCommandReportBulkUpload" */ '@/views/ad/AdCommandReportBulkUpload.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReportBulkUpload') },
  }, {
    path: '/ad/command_report/:id',
    name: 'AdCommandReportItem',
    component: () => import(/* webpackChunkName: "AdCommandReportItem" */ '@/views/ad/AdCommandReportItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReport') },
    props: true,
  }, {
    path: '/ad/command_report/:id/edit',
    name: 'AdCommandReportEdit',
    component: () => import(/* webpackChunkName: "AdCommandReportEdit" */ '@/views/ad/AdCommandReportEdit.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.adCommandReport') + ' - ' + v.$t('operation.edit') },
    props: true,
  },

  {
    path: '/misc/operator',
    name: 'MiscOperatorList',
    component: () => import(/* webpackChunkName: "MiscOperatorList" */ '@/views/misc/MiscOperatorList.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.miscOperator') },
  }, {
    path: '/misc/operator/:id',
    name: 'MiscOperatorItem',
    component: () => import(/* webpackChunkName: "MiscOperatorItem" */ '@/views/misc/MiscOperatorItem.vue'),
    meta: { titleBuilder: (v) => v.$t('menu.miscOperator') },
    props: true,
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router

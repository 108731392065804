import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginRequired: false,
    loggedInfo: {},
  },
  getters: {
    getLoginRequired(state) {
      return state.loginRequired;
    },
    getLoggedInfo(state) {
      return state.loggedInfo;
    },
    getLoggedUsername(state) {
      return state.loggedInfo.username;
    },
    getLoggedName(state) {
      return state.loggedInfo.name;
    },
    getUserGroupType(state) {
      return state.loggedInfo.groupType;
    },
    getBasicPermissions(state) {
      return state.loggedInfo.basicPermissions;
    },
  },
  mutations: {
    setLoginRequired(state, payload) {
      state.loginRequired = payload;
    },
    setLoggedInfo(state, payload) {
      state.loggedInfo = payload;
    },
  },
  actions: {
    async getLoggedInfo(context) {
      try {
        let res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/info`, {
          params: {}
        });
        context.commit('setLoginRequired', false);
        context.commit('setLoggedInfo', res.data || {});
      }
      catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          const errorCode = error.response.data.error;

          if (errorCode === 'loginRequired') {
            context.commit('setLoginRequired', true);
          }

          context.commit('setLoggedInfo', {});
        }
      }
    },

    async clearLoggedInfo(context) {
      context.commit('setLoggedInfo', {});
    },
  },
});
<template>
  <v-app :class="[(isLoginPage ? 'login-background' : '')]">

    <template v-if="isLoginPage">
      <v-main>
        <v-layout column fill-height>
          <router-view />
        </v-layout>
      </v-main>
    </template>

    <template v-if="!isLoginPage">
      
      <template v-if="loggedUsername == null">
        <v-main>
          <v-layout column align-center justify-center>
            <div class="pa-4">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-layout>
        </v-main>
      </template>

      <template v-if="loggedUsername != null">
        <v-app-bar
          app
          color="primary"
          dark
          clipped-left
        >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
              <router-link to="/" class="d-flex flex-column toolbar-link">
                <span>STAMS</span>
                <span class="text-subtitle-2 d-none d-sm-flex">서울시 택시광고 관리시스템</span>
              </router-link>
            </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-menu v-if="basicPermissions && (basicPermissions.adCommand.edit || basicPermissions.adCommandReport.edit)" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon left>mdi-circle-edit-outline</v-icon>
                {{ $t('menu.tools') }}
              </v-btn>
            </template>

            <v-list subheader>
              <template v-if="basicPermissions && basicPermissions.adCommand.edit">
                <v-subheader>{{ $t('menu.adCommand') }}</v-subheader>

                <v-list-item link to="/ad/command/create_wizard">
                  <v-icon left>mdi-auto-fix</v-icon>
                  <v-list-item-title>{{ $t('menu.adCommandCreationWizard') }}</v-list-item-title>
                </v-list-item>

                <v-list-item link to="/ad/command/review_wizard">
                  <v-icon left>mdi-playlist-check</v-icon>
                  <v-list-item-title>{{ $t('menu.adCommandReviewWizard') }}</v-list-item-title>
                </v-list-item>
              </template>

              <v-divider v-if="basicPermissions && basicPermissions.adCommand.edit && basicPermissions.adCommandReport.edit"></v-divider>

              <template v-if="basicPermissions && basicPermissions.adCommandReport.edit">
                <v-subheader>{{ $t('menu.adCommandReport') }}</v-subheader>

                <v-list-item link to="/ad/command_report/create_wizard">
                  <v-icon left>mdi-auto-fix</v-icon>
                  <v-list-item-title>{{ $t('menu.adCommandReportCreationWizard') }}</v-list-item-title>
                </v-list-item>

                <v-list-item link to="/ad/command_report/bulk_upload">
                  <v-icon left>mdi-upload</v-icon>
                  <v-list-item-title>{{ $t('menu.adCommandReportBulkUpload') }}</v-list-item-title>
                </v-list-item>
              </template>
              
            </v-list>
          </v-menu>

          <v-menu min-width="200" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-avatar color="brown" size="32" class="mr-2">
                  <span class="white--text text-h6">{{ loggedNamePrefix }}</span>
                </v-avatar>
                {{ loggedName }}
              </v-btn>
            </template>

            <v-card>
              <v-list-item two-line>
                <v-avatar color="brown" size="40" class="mr-2">
                  <span class="white--text text-h5">{{ loggedNamePrefix }}</span>
                </v-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ loggedName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ loggedUsername }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list>
                <v-list-item link to="/user/logged_info">
                  <v-list-item-title>{{ $t('menu.myAccount') }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="logout">
                  <v-list-item-title>{{ $t('operation.logout') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app clipped>
          <v-list nav dense>
            <v-list-item-group
              v-model="drawerSelectedItemIndex"
              color="primary"
            >
              <template v-for="(item, i) in drawerItems">

                <v-list-group v-if="item.type == 'group'" :key="`drawerItem-${i}`" :value="true" :prepend-icon="item.icon" no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item v-for="(subitem, j) in item.items" :key="`drawerSubitems-${i}-${j}`" :to="subitem.link" link>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(subitem.title) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-item v-if="item.type == 'item'" :key="`drawerItem-${i}`" :to="item.link" link>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </template>
            </v-list-item-group>

          </v-list>
        </v-navigation-drawer>

        <v-main>
          <v-layout column fill-height>
            <router-view />
            <v-footer class="mt-auto" padless>
              <v-card width="100%" class="white text-center py-2" flat tile>
                <v-card-text>
                  &copy; KIMG & NCC. All rights reserved.
                </v-card-text>
              </v-card>
            </v-footer>
          </v-layout>

          <v-btn fab small dark fixed bottom right color="primary" @click="$vuetify.goTo(0)">
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>

        </v-main>
      </template>

    </template>
    
    <MessageSnackBar ref="messageSnackBar"></MessageSnackBar>

  </v-app>
</template>

<script>
import MessageSnackBar from '@/components/MessageSnackBar.vue';

export default {
  name: 'App',

  components: {
    MessageSnackBar,
  },

  data: () => ({
    drawer: true,
    drawerItems: [],
    drawerSelectedItemIndex: 0,
  }),

  computed: {
    isLoginPage() {
      return this.$route.name === 'UserLogin';
    },
    loginRequired() {
      return this.$store.getters.getLoginRequired;
    },
    loggedUsername() {
      return this.$store.getters.getLoggedUsername;
    },
    loggedName() {
      return this.$store.getters.getLoggedName;
    },
    loggedNamePrefix() {
      let loggedName = this.loggedName || '';
      return loggedName.length > 0 ? loggedName.toUpperCase()[0] : '';
    },
    basicPermissions() {
      return this.$store.getters.getBasicPermissions;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        let pageTitle = to.meta.titleBuilder ? to.meta.titleBuilder(this) : '';
        if (pageTitle.length > 0) pageTitle += ' - ';
        pageTitle += 'STAMS';
        document.title = pageTitle;
      },
    },
    loginRequired: {
      handler(to) {
        if (this.isLoginPage) return;
        if (to) this.$router.replace({ name: 'UserLogin' });
      },
    },
    basicPermissions: {
      handler(to) {
        if (!to) {
          this.drawerItems = [];
          return;
        }

        let drawerItems = [];
        drawerItems.push({
          type: 'item', title: 'menu.home', icon: 'mdi-home', link: '/'
        });
        if (to.adCommand.list) {
          drawerItems.push({
            type: 'group', title: 'menu.association', icon: 'mdi-account-multiple',
            items: [
              ...(to.adCommand.list ? [{ title: 'menu.exportAssociationSettlement', link: '/association/settlement' }] : []),
            ],
          });
        }
        if (to.district.list || to.carrier.list || to.taxi.list || to.taxiType.list) {
          drawerItems.push({
            type: 'group', title: 'menu.taxi', icon: 'mdi-taxi',
            items: [
              ...(to.district.list ? [{ title: 'menu.taxiDistrict', link: '/taxi/district' }] : []),
              ...(to.carrier.list ? [{ title: 'menu.taxiCarrier', link: '/taxi/carrier' }] : []),
              ...(to.taxi.list ? [{ title: 'menu.taxi', link: '/taxi/taxi' }] : []),
              ...(to.taxiType.list ? [{ title: 'menu.taxiType', link: '/taxi/type' }] : []),
            ],
          });
        }
        if (to.advertiser.list || to.ad.list || to.adUnit.list || to.adCommandGroup.list || to.adCommand.list || to.adCommandReport.list) {
          drawerItems.push({
            type: 'group', title: 'menu.ad', icon: 'mdi-view-dashboard',
            items: [
              ...(to.advertiser.list ? [{ title: 'menu.adAdvertiser', link: '/ad/advertiser' }] : []),
              ...(to.ad.list ? [{ title: 'menu.ad', link: '/ad/ad' }] : []),
              ...(to.adUnit.list ? [{ title: 'menu.adUnit', link: '/ad/unit' }] : []),
              ...(to.adCommandGroup.list ? [{ title: 'menu.adCommandGroup', link: '/ad/command_group' }] : []),
              ...(to.adCommand.list ? [{ title: 'menu.adCommand', link: '/ad/command' }] : []),
              ...(to.adCommandReport.list ? [{ title: 'menu.adCommandReport', link: '/ad/command_report' }] : []),
            ],
          });
        }
        if (to.union.list || to.worker.list || to.operator.list) {
          drawerItems.push({
            type: 'group', title: 'menu.miscellaneous', icon: 'mdi-layers',
            items: [
              ...(to.union.list ? [{ title: 'menu.taxiUnion', link: '/taxi/union' }] : []),
              ...(to.worker.list ? [{ title: 'menu.taxiWorker', link: '/taxi/worker' }] : []),
              ...(to.operator.list ? [{ title: 'menu.miscOperator', link: '/misc/operator' }] : []),
            ],
          });
        }

        this.drawerItems = drawerItems;
      },
    },
  },

  created() {
    this.$store.dispatch('getLoggedInfo');
  },

  mounted() {
    this.$root.messageSnackBar = this.$refs.messageSnackBar;
  },

  methods: {
    logout() {
      this.$apiRequest({
        method: 'post',
        url: '/user/logout',
      }).then((res) => {
        if (!res.data) return;
        this.$store.dispatch('clearLoggedInfo');
        this.$router.push({ name: 'UserLogin' });
      });
    },
  },
};
</script>

<style scoped>
.login-background {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 30%, rgba(0, 0, 0, 0.5) 100%), url('~@/assets/images/login_background.jpg') no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.toolbar-link {
  color: #ffffff;
  text-decoration: none;
}
</style>
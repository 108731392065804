<template>
  <div>
    <v-flex class="common-list-flex">
      <v-data-table
        class="common-list-table transparent"        
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :headers="tableHeaders"
        :items="items"
        :server-items-length="fetchAll ? -1 : numTotalItems"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50],
          showFirstLastPage: true,
        }"
        :mobile-breakpoint="0"
        :show-select="checkable"
        dense
        hide-default-footer
      >

        <template v-slot:top>
          <v-container fluid>
            <v-row justify="space-between" align="center">
              <v-col cols="12" md="8" sm="6">
                <v-toolbar-title v-if="!hideTitle && (title.length > 0)" class="font-weight-bold pr-2">
                  {{ title }}
                  <template v-if="checkable">({{ numCheckedItems }} / {{ numTotalItems }})</template>
                  <template v-if="!checkable">({{ numTotalItems }})</template>
                </v-toolbar-title>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div class="d-flex flex-row align-center">
                  <v-text-field
                    v-model="searchField"
                    append-icon="mdi-magnify"
                    :label="$t('operation.search')"
                    single-line
                    hide-details
                    solo
                    clearable
                    @change="onSearchEnter"
                  ></v-text-field>

                  <v-btn v-if="!isWizardList && advancedSearch.length > 0" :color="showAdvancedSearch ? 'primary' : ''" @click="showAdvancedSearch = !showAdvancedSearch" class="ml-2" text>{{ $t('operation.advancedSearch') }}</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-expand-transition v-if="!isWizardList && advancedSearch.length > 0">
            <v-responsive v-if="showAdvancedSearch">
                <v-sheet class="mx-4 mb-2" outlined rounded>
                  <v-container fluid>
                    <v-row>
                      <v-col v-for="item in advancedSearch" :key="item.key" cols="12" md="3" sm="6">
                        <div class="text-caption">{{ item.title }}</div>

                        <template v-if="item.type === 'checkbox'">
                          <v-layout>
                            <v-checkbox v-for="checkItem in item.list" :key="checkItem.value" :label="checkItem.text" :value="checkItem.value" v-model="advancedParams[item.key]" @change="onSearchEnter" class="shrink mr-3" dense hide-details>
                            </v-checkbox>
                          </v-layout>
                        </template>

                        <template v-if="item.type === 'multiselect'">
                          <v-autocomplete :items="item.list" v-model="advancedParams[item.key]" dense outlined multiple clearable hide-details>
                          </v-autocomplete>
                        </template>

                        <template v-if="item.type === 'date'">
                          <v-text-field v-model="advancedParams[item.key]" type="date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" dense outlined hide-details clearable></v-text-field>
                        </template>

                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
            </v-responsive>
          </v-expand-transition>
        </template>

        <template v-slot:[`header.data-table-select`]>
          <v-checkbox v-model="allChecked"></v-checkbox>
        </template>

        <template v-slot:item="{ item, index }">
          <tr>
            <td v-if="checkable"><v-checkbox v-model="item.checked" /></td>
            <td>{{ ((page-1) * itemsPerPage) + index + 1 }}</td>
            <td v-for="(header, i) in headers" :key="header.value">
              <template v-if="!isWizardList && !disableRowClick && item.link">
                <router-link :to="item.link" class="text-decoration-none black--text">
                  <span :class="['clickable', (i == 0 ? 'font-weight-bold' : ''), (item[header.value].color ? item[header.value].color+'--text' : '')]">
                    {{ item[header.value].text }}
                  </span>
                </router-link>
              </template>
              <span v-if="isWizardList || disableRowClick || !item.link" @click="onRowClick(item, index)" :class="[((disableRowClick || !item.link) ? '' : 'clickable'), (i == 0 ? 'font-weight-bold' : ''), (item[header.value].color ? item[header.value].color+'--text' : '')]">
                {{ item[header.value].text }}
              </span>
            </td>
          </tr>
        </template>

        <template v-slot:footer>
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="6">
                <v-btn v-if="!disableCreation && editable" color="primary" class="mr-2" @click="showCreateDialog">
                  <v-icon left>mdi-pencil</v-icon>
                  {{ $t('operation.create') }}
                </v-btn>

                <v-menu v-if="!isWizardList" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" :disabled="loading">
                      <v-icon left>mdi-download</v-icon>
                      {{ $t('operation.export') }}
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item link @click="exportData(true)">
                      <v-list-item-title>{{ $t('operation.exportCurrentPage') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="exportData(false)">
                      <v-list-item-title>{{ $t('operation.exportAll') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="d-flex justify-end">
                  <v-pagination v-model="page" :length="pageCount" :disabled="loading"></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>

      </v-data-table>
    </v-flex>

    <v-dialog v-model="exporting" width="300" persistent>
      <v-card>
        <v-card-title>
          {{ $t('message.exportInProgress') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear :indeterminate="exportProgress < 0" :value="exportProgress * 100"></v-progress-linear>
          <div class="mt-2 text-right" v-if="exportProgress >= 0">
            {{ Math.floor(exportProgress * 100) }}%
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="createDialog" v-model="createDialog" persistent max-width="600px">
      <CommonEdit
        ref="commonEdit"
        class="pa-4"
        :url="url"
        :initialValues="editInitialValues"
        @finish="createDialog = false; fetchData();"
        @cancel="createDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import JSURL from 'jsurl';
import XLSX from 'xlsx';

import CommonEdit from '@/components/CommonEdit.vue';

export default {
  name: 'CommonList',

  components: {
    CommonEdit,
  },

  props: {
    isSublist: {
      default: false,
    },
    isWizardList: {
      default: false,
    },
    hideTitle: {
      default: false,
    },
    checkable: {
      default: false,
    },
    defaultCheck: {
      default: false,
    },
    title: {
      default: '',
    },
    url: {
      default: '',
    },
    fetchAll: {
      default: false,
    },
    params: {
      default: () => {},
    },
    initialAdvancedParams: {
      default: undefined,
    },
    disableCreation: {
      default: false,
    },
    disableRowClick: {
      default: false,
    },
    editInitialValues: {
      default: () => ({}),
    },
  },

  data: () => ({
    modifying: false,
    loading: false,
    initialLoaded: false,
    headers: [],
    tableHeaders: [],
    items: [],
    editable: false,

    searchField: '',
    sublistPage: 1,
    sublistItemsPerPage: 10,
    sublistSortBy: '',
    sublistSearch: '',
    sortIn: 'asc',
    numTotalItems: 0,
    advancedSearch: [],
    showAdvancedSearch: false,
    advancedParams: {},

    exporting: false,
    exportProgress: -1,

    createDialog: false,
  }),

  computed: {
    page: {
      get() {
        if (this.isSublist) return this.sublistPage || 1;
        return Number(this.$route.query.page || 1);
      },
      set(newPage) {
        if (this.isSublist) this.sublistPage = newPage;
        else {
          if (this.page !== newPage) this.$router.push({ query: { ...this.$route.query, page: newPage } });
        }
        if (!this.fetchAll) this.fetchData();
      }
    },
    itemsPerPage: {
      get() {
        if (this.isSublist) return this.sublistItemsPerPage || 10;
        return Number(this.$route.query.ipp || 20);
      },
      set(newItemsPerPage) {
        if (this.isSublist) this.sublistItemsPerPage = newItemsPerPage;
        else {
          if (this.itemsPerPage !== newItemsPerPage) this.$router.push({ query: { ...this.$route.query, ipp: newItemsPerPage } });
        }
        if (!this.fetchAll) this.fetchData();
      }
    },
    pageCount() {
      return Math.ceil(this.numTotalItems / this.itemsPerPage);
    },
    sortBy: {
      get() {
        if (this.isSublist) return this.sublistSortBy || '';
        return this.$route.query.sortBy || '';
      },
      set(newSortBy) {
        if (this.isSublist) this.sublistSortBy = newSortBy;
        else {
          if (this.sortBy !== newSortBy) this.$router.push({ query: { ...this.$route.query, sortBy: newSortBy } });
        }
        if (!this.fetchAll) this.fetchData();
      }
    },
    sortDesc: {
      get() {
        if (this.isSublist) return (this.sortIn == 'desc');
        return (this.$route.query.sortIn == 'desc');
      },
      set(newSortDesc) {
        this.sortIn = (newSortDesc ? 'desc' : 'asc');
        if (!this.isSublist) {
          if (this.sortDesc !== newSortDesc) this.$router.push({ query: { ...this.$route.query, sortIn: this.sortIn } });
        }
        if (!this.fetchAll) this.fetchData();
      },
    },
    search: {
      get() {
        if (this.isSublist) return this.sublistSearch || '';
        return this.$route.query.search || '';
      },
      set(newSearch) {
        if (this.isSublist) this.sublistSearch = newSearch;
        else {
          if (this.search !== newSearch) this.$router.push({ query: { ...this.$route.query, search: newSearch } });
        }
        if (!this.fetchAll) this.fetchData();
      }
    },
    allChecked: {
      get() {
        let checked = true;
        for (let item of this.items) {
          if (!item.checked) {
            checked = false;
            break;
          }
        }
        return checked;
      },
      set(newAllChecked) {
        for (let item of this.items) item.checked = newAllChecked;
      },
    },
    numCheckedItems() {
      let numItems = 0;
      this.items.forEach((item) => {
        if (item.checked) numItems += 1;
      });
      return numItems;
    },
  },

  watch: {
    advancedParams: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },

  created() {
    this.modifying = true;

    if (this.$route.query.advanced) {
      this.advancedParams = JSURL.parse(this.$route.query.advanced);
    }
    else {
      this.advancedParams = this.initialAdvancedParams || {};
    }

    if (!this.isSublist && Object.keys(this.advancedParams).length > 0) {
      this.showAdvancedSearch = true;
    }

    this.searchField = this.$route.query.search || '';

    this.modifying = false;
    this.fetchData();
    this.fetchProps();
  },

  methods: {
    onSearchEnter() {
      this.modifying = true;
      this.page = 1;
      this.search = this.searchField;
      this.modifying = false;
      this.fetchData();
    },

    onRowClick(item, index) {
      if (this.disableRowClick) return;
      if (item.link == null) return;

      if (this.isWizardList) {
        this.$emit('click', {item, index});
      }
      else {
        this.$router.push({ path: item.link });
      }
    },

    fetchData() {
      if (this.modifying) return;
      if (this.loading) return;
      this.loading = true;

      this.queryData({
        page: this.fetchAll ? 1 : this.page,
        limit: this.fetchAll ? 100000 : this.itemsPerPage,
        initialLoaded: this.initialLoaded,
      }).then((res) => {
        if (!res.data) return;

        this.headers = res.data.headers.map((item) => {
          return {
            text: item.text,
            value: item.key,
            sortable: item.sortable || false,
          };
        });
        this.tableHeaders = [{
          text: this.$t('listKey.no'),
          value: 'no',
          sortable: false,
        }, ...this.headers];
        this.items = res.data.rows.map((item) => {
          return {
            ...item,
            checked: (this.defaultCheck ? true : false),
          };
        });
        this.numTotalItems = res.data.numTotalRows;

        if (!this.initialLoaded) {
          this.advancedSearch = res.data.advancedSearch;
          this.updateAdvancedParams();
        }

        this.loading = false;
        this.initialLoaded = true;
      });
    },

    fetchProps() {
      this.$apiRequest({
        method: 'get',
        url: this.url + '/props',
      }).then((res) => {
        if (!res.data) return;
        this.editable = !!(res.data.editable);
      });
    },

    async queryData({ page, limit, initialLoaded=true } = {}) {
      let params = {
        page: page,
        limit: limit,
        sortKey: this.sortBy,
        sortIn: this.sortDesc ? 'desc' : 'asc',
        search: this.search,
      };
      for (let key in this.params) {
        params[key] = this.params[key];
      }
      let advancedParams = this.advancedParams || {};
      if (advancedParams) {
        params['advanced'] = JSURL.stringify(advancedParams);
      }
      params['withAdvancedSearchInfo'] = initialLoaded ? 0 : 1;

      return this.$apiRequest({
        method: 'get',
        url: this.url,
        data: params,
      });
    },

    updateAdvancedParams() {
      for (let item of this.advancedSearch) {
        if (item.type === 'checkbox') {
          this.advancedParams[item.key] = this.advancedParams[item.key] || [];
        }
      }
    },

    exportData(currentPageOnly) {
      if (this.exporting) return;
      this.exportProgress = -1;
      this.exporting = true;

      const _export = async () => {
        let data = [];

        // export header
        const headerData = this.headers.map((header) => {
          return header.text;
        });
        data.push(headerData);

        if (currentPageOnly) {
          for (let item of this.items) {
            const currentData = this.headers.map((header) => {
              return item[header.value].text;
            });
            data.push(currentData);
          }
        }
        else {
          const itemsPerPage = 1000;
          const pageCount = Math.ceil(this.numTotalItems / itemsPerPage);
          this.exportProgress = 0;

          for (let i=0; i<pageCount; i++) {
            let res = await this.queryData({
              page: i+1,
              limit: itemsPerPage,
            });

            if (res.data.rows) {
              for (let item of res.data.rows) {
                const currentData = this.headers.map((header) => {
                  return item[header.value].text;
                });
                data.push(currentData);
              }
            }

            this.exportProgress = (i+1) / pageCount;
            await new Promise(r => setTimeout(r, 300));
          }
        }

        let ws = XLSX.utils.aoa_to_sheet(data);
        ws['!cols'] = data[0].map((a) => ({
          wch: Math.round(Math.max(16, Math.min(32, a.toString().length*3))),
        }));
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.title || 'Sheet');
        XLSX.writeFile(wb, 'export.xlsx');

        this.exporting = false;
      };

      _export();
    },

    showCreateDialog() {
      this.createDialog = true;
    },

    getCheckedItems() {
      let checkedItems = [];
      for (let item of this.items) {
        if (item.checked) checkedItems.push(item);
      }
      return checkedItems;
    },
  }
  
};
</script>

<style>
.common-list-flex {
  overflow-x: auto;
}
.common-list-table th, .common-list-table td {
  padding: 0 8px;
}
.common-list-table tr {
  padding-left: 8px;
  padding-right: 8px;
}
.common-list-table tr > th:first-child {
  width: 0.1%;
}
.common-list-table tr > td:first-child {
  width: 0.1%;
}
.common-list-table th span {
  white-space: nowrap;
}
.common-list-table td .clickable {
  display: block;
  cursor: pointer;
}
</style>